import React, { useState, useEffect } from "react"
import Tracking from "./tracking"

const Cookies = () => {
  const [hideMessage, setHideMessage] = useState('load')
  const setCookie = () => {
    window.localStorage.setItem("devstyleCookie", "accepted")
    setHideMessage('accepted')
  }

  useEffect(() => localStorage.getItem("devstyleCookie") ? setHideMessage('accepted') : setHideMessage('unset'), [])

  return (hideMessage === 'unset') ? (
    <div className="fixed bottom-4 z-20 w-full px-4">
      <div className="bg-white rounded-md border border-gray-200 p-4 mx-auto max-w-7xl shadow-lg flex flex-col sm:flex-row items-center">
        <p className="text-sm m-0">
          Wykorzystujemy pliki cookie do spersonalizowania treści i reklam, aby
          oferować funkcje społecznościowe i analizować ruch w naszej witrynie.
          Informacje o tym, jak korzystasz z naszej witryny, udostępniamy
          partnerom społecznościowym, reklamowym i analitycznym. Partnerzy mogą
          połączyć te informacje z innymi danymi otrzymanymi od Ciebie lub
          uzyskanymi podczas korzystania z ich usług.
        </p>
        <button
          onClick={setCookie}
          className="text-orange-500 flex py-4 px-6 ml-6 mt-6 sm:mt-0 border border-gray-200  text-sm font-bold 
          rounded-md "
        >
          Rozumiem
        </button>
      </div>
    </div>
  ) : hideMessage === 'accepted' ? <Tracking/> : null
}

export default Cookies

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="mdx-content">
      <h1>{`FAQ`}</h1>
      <h2>{`Kiedy dostanę dostęp do materiałów?`}</h2>
      <p>{`Twoje konto zostanie utworzone na `}<a target="_blank" href="https://edu.devstyle.pl">{`platformie edukacyjnej »`}</a>{` od razu po dokonaniu płatności. Dane dostępowe otrzymasz na adres e-mail podany przy składaniu zamówienia.`}</p>
      <h2>{`Jak długo mam dostęp do materiałów?`}</h2>
      <p>{`Dostęp do szkoleń w devstyle jest dożywotni. Kupujesz teraz – na zawsze. Jeśli pojawią się aktualizacje czy uzupełnienia w kolejnych edycjach, otrzymasz je gratis. Wyjątkiem są małe, krótkie kursy z `}<a target="_blank" href="https://devbites.pl">{`devbites »`}</a>{` - tam kupujesz dostęp na rok (ale jest to wyraźnie napisane na stronie kursu).`}</p>
      <h2>{`Na czym polega gwarancja zadowolenia?`}</h2>
      <p>{`Masz 14 dni na zapoznanie się z zakupionymi materiałami. Jeśli w tym czasie cokolwiek Ci się nie spodoba - po prostu napisz do nas i zwrócimy Ci 100% pieniędzy. Bez żadnej dramy. Z dowolnego powodu. `}<strong parentName="p">{`Niczym nie ryzykujesz!`}</strong></p>
      <h2>{`Czy mogę ściągnąć materiały i oglądać je offline?`}</h2>
      <p>{`Wideo jest do streamingu online. Ściągniesz za to kod, slajdy i wszystkie materiały pomocnicze.`}</p>
      <h2>{`Czy materiały są dostępne na otwartej platformie?`}</h2>
      <p>{`Nasze materiały są hostowane na własnej platformie. Nie przewidujemy umieszczenia ich nigdzie indziej.`}</p>
      <h2>{`Czy otrzymam fakturę VAT?`}</h2>
      <p>{`Tak, oczywiście. Faktura VAT 23% przychodzi na maila automatycznie, od razu po opłaceniu zamówienia. Nasze produkty możesz „wrzucić w koszty”.
Jeśli potrzebujesz faktury proforma - zaznacz odpowiednią opcję podczas składania zamówienia.`}</p>
      <h2>{`A może dodatkowy rabacik dla...?`}</h2>
      <p>{`Nie udzielamy rabatów. Dodatkowe zniżki przewidujemy wyłącznie przy większych zakupach firmowych: do każdych 10 dostępów dodajemy 1 gratis.`}</p>
      <h2>{`Czy można zamówić z zagranicy?`}</h2>
      <p>{`Tak. Jednak platforma sklepowa nie wspiera zagranicznych danych, więc w tym celu napisz na `}<a parentName="p" {...{
          "href": "mailto:kontakt@devstyle.pl"
        }}>{`kontakt@devstyle.pl`}</a>{`.`}</p>
      <h2>{`Czy można kupić wiele dostępów naraz?`}</h2>
      <p>{`To informacja istotna szczególnie dla firm. TAK, można kupić wiele dostępów, składając jedno grupowe zamówienie (po prostu zwiększ liczbę sztuk w koszyku). Po opłaceniu zamówienia wyślij nam dane Uczestników (imię, nazwisko, email).`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
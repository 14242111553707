import React from "react";
import { Link } from "gatsby";
import Image from "./image";

const OrangeBox = () => (
  <section className="overflow-hidden py-10 lg:py-32 px-4">
    <div className="max-w-7xl mx-auto bg-orange-500 text-white rounded-3xl px-4 sm:px-24 py-10 sm:py-20 relative">
      <Image
        src="content/devstyle-black.svg"
        className="absolute -top-9 -left-36 hidden lg:block"
      />

      <h2 className="text-center text-base md:text-xl mb-2">
        Dołącz do 20&nbsp;000+ programistów korzystających z naszych Szkoleń
      </h2>
      <p className="text-center font-semibold text-2xl md:text-4xl leading-tight">
        Zwiększ swój programistyczny potencjał dzięki wiedzy najlepszych
        ekspertów
      </p>
      <div className="flex justify-center mt-8">
        <Link className="btn-white mx-2" to="/o-nas">
          Poznaj nas
        </Link>
        <a className="btn-white-alternative mx-2" href="/#szkolenia">
          Wybierz szkolenie
        </a>
      </div>
    </div>
  </section>
);

export default OrangeBox;

import React from "react";
import { Link } from "gatsby";
import Logo from "../images/edu.svg";
import LogoDark from "../images/edu-dark.svg";

const Header = ({theme}) => {
  return (
    <div className={`${theme ? 'bg-white bg-opacity-70 backdrop-filter  backdrop-blur-sm' : 'bg-black'} sticky top-0 w-full z-50`}>
      <header className="max-w-7xl mx-auto flex items-center justify-between py-5 lg:py-6 px-4">
       
          <Link to="/">
            <img width="126" height="40" className="h-8 lg:h-10 " src={theme ? LogoDark : Logo} alt="Edu Devstyle" />
          </Link>
          <a
            className="btn-orange"
            href="/#szkolenia"
          >
            Wybierz szkolenie 
          </a>
      </header>
    </div>
  );
};

export default Header;

import * as React from "react";
import { Link } from "gatsby";
import Image from "./image";
import OrangeBox from "./orange-box";

const Footer = (props) => {
  return (
    <>
      <OrangeBox />

      <footer className="bg-gray-100 pt-5 lg:pt-20 pb-10">
        <div className="max-w-7xl mx-auto ">
          <div className="border-b border-border pb-5 lg:pb-20 lg:flex justify-between items-center">
            <nav className="px-4 border-border border-b pb-5 lg:pb-0 lg:border-none">
              <ul className="flex gap-6 flex-wrap lg:flex-nowrap tracking-tight font-semibold">
                <li className="w-full lg:w-auto">
                  <a
                    href="https://devstyle.pl"
                    rel="noreferrer"
                    target="_blank"
                    title="Devstyle Blog"
                  >
                    <Image
                      src="content/devstyle-mini.svg"
                      alt="DevStyle"
                      className="h-6"
                      width="115"
                      height="24"
                    />
                  </a>
                </li>
                <li className="w-1/3 lg:w-auto">
                  <a href="/#szkolenia">Szkolenia</a>
                </li>
                <li className="w-1/3 lg:w-auto">
                  <Link to="/o-nas">O nas</Link>
                </li>
                <li className="w-1/3 lg:w-auto">
                  <Link to="/faq">FAQ</Link>
                </li>
                <li className="w-1/3 lg:w-auto">
                  <Link to="/kontakt">Kontakt</Link>
                </li>
              </ul>
            </nav>
            <p className="pt-5 lg:pt-0 lg:text-right tracking-tight px-4">
              Masz pytania lub sugestie? Chcesz złożyć większe zamówienie?
              <br />
              Napisz do nas{" "}
              <a className="font-semibold" href="mailto:kontakt@devstyle.pl">
                kontakt@devstyle.pl
              </a>
            </p>
          </div>
          <div className="pt-5  lg:pt-10 flex flex-col items-start lg:flex-row lg:items-center lg:justify-between tracking-tight">
            <div className="flex gap-3 px-4  flex-col lg:flex-row pb-5 lg:pb-0">
              <p className="text-gray-500">Copyright &copy; {(new Date().getFullYear())} devstyle.pl</p>
              <span className="hidden lg:block">&middot;</span>
              <Link className="text-gray-700" to="https://szkolenia.devstyle.pl/regulamin">
                Regulamin
              </Link>
              <Link className="text-gray-700" to="https://szkolenia.devstyle.pl/polityka-prywatnosci">
                Polityka prywatności
              </Link>
              <Link className="text-gray-700" to="https://szkolenia.devstyle.pl/regulamin-newslettera">
                Regulamin newslettera
              </Link>
              <Link className="text-gray-700" to="https://szkolenia.devstyle.pl/polityka-opinii">
                Polityka opinii
              </Link>
            </div>
            <div className="flex gap-3 px-4 w-full justify-center lg:w-auto border-border border-t lg:border-none pt-5 lg:pt-0">
              <a
                href="https://www.facebook.com/devstylepl"
                target="_blank"
                rel="noreferrer"
                title="Facebook"
              >
                <Image width="32" height="32" src="Icons/Facebook.svg" alt="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/maciej.aniserowicz/"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <Image width="32" height="32" src="Icons/Instagram.svg" alt="Instagram" />
              </a>
              <a
                href="https://www.linkedin.com/in/maciejaniserowicz/"
                target="_blank"
                rel="noreferrer"
                title="Linkedin"
              >
                <Image width="32" height="32" src="Icons/Linkedin.svg" alt="Linkedin" />
              </a>
            </div>
          </div>
          <div className="pt-5  lg:pt-10 flex flex-col items-start lg:flex-row lg:items-center lg:justify-between tracking-tight">
            <div className="flex gap-3 px-4  flex-col lg:flex-row pb-5 lg:pb-0">
              <p className="text-gray-500">
                DEVSTYLE spółka z ograniczona odpowiedzialnością, ul. Marii Konopnickiej 14 /8, 15-215 Białystok; Tel. +48 452 246 901;<br />
                NIP: 5423453088, Sąd Rejonowy w Białymstoku, XII Wydział Gospodarczy - Krajowego Rejestru Sądowego KRS 0000983500; REGON: 522649044
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
